<template>
  <div class="public">
    <router-view />
    <div class="about-dev">
      Powered by <a href="https://redentu.com">Redentu</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Public',
};
</script>

<style lang="scss" scoped>
  .public {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .about-dev {
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);

      a {
        text-decoration: unset;
      }
    }
  }
</style>
